import React from "react";
import Details from "../../Components/Details";
import cardImage1 from "../../assist/Images/Swiper/Understanding.jpg";
import cardImage2 from "../../assist/Images/Swiper/Brainstorming.jpg";
import cardImage3 from "../../assist/Images/Swiper/Presentation.jpg";
import cardImage4 from "../../assist/Images/Swiper/review and correction.jpg";
import cardImage5 from "../../assist/Images/Swiper/launch.jpg";
import cardImage6 from "../../assist/Images/Swiper/feedback.jpg";
import cardIcon1 from "../../assist/Images/cardIcon1.png";
import line from "../../assist/Images/line.png";
const Creative = () => {
  const data = {
    heading: "Our Creative Process",
    title:
      "Fusing Creativity With Marketing & Technology To Drive Business Growth",
    secondTitle: "From Concept to Reality",
    creative: [
      {
        imgs: cardImage1,
        main: "Understanding",
        sub: "Intercation",
        para: "Deep study of product, ideal customers and competitors for setting goals.",
      },
      {
        imgs: cardImage2,
        main: "Brainstorming",
        sub: "Collaboration",
        para: "Bringing out of the box ideas and planning for the goal.",
      },
      {
        imgs: cardImage3,
        main: "Presentation",
        sub: "Discovery & strategy workshop",
        para: "Presenting our ideas in a draft and move further for approval.",
      },
      {
        imgs: cardImage4,
        cicon: cardIcon1,
        main: "Review & Correction",
        sub: "Discovery & strategy workshop",
        para: "We go under multiple review and correction to bring the right planning for the goal.",
      },
      {
        imgs: cardImage5,
        cicon: cardIcon1,
        main: "Launch",
        sub: "Discovery & strategy workshop",
        para: "Set our team with proper assignment and let the planning go live.",
      },
      {
        imgs: cardImage6,
        cicon: cardIcon1,
        main: "Feedback",
        sub: "Discovery & strategy workshop",
        para: "Look back to journals of the project and keep it as a record to analyze.",
      },
    ],
  };

  return (
    <>
      <div className="container pt-[161px] ">
        <Details details={data} />
        {/* <div className="content-wrapper flex justify-around px-10 mt-6">
          <div className="left-content text-[#004A80] text-[36px] font-[700]">
            <h1>Ideas into reality</h1>
          </div>
        </div> */}
        <div className="line pt-20 pl-20 ">
          <img src={line} alt="" />
        </div>
      </div>
    </>
  );
};

export default Creative;
