import React from "react";

const Loading = () => {
  return (
    <div className="loadingSpinner h-screen w-screen grid place-content-center ">
      <span class="loader "> </span>
    </div>
  );
};

export default Loading;
