import React, { useEffect, useState } from "react";
import Logo from "../assist/Images/unitech logo 2.png";
import ham from "../assist/Images/ham_small.png";
import { Link, NavLink } from "react-router-dom";
const Navbar = () => {
  const Menu = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-menu"
      >
        <line x1="4" x2="20" y1="12" y2="12"></line>
        <line x1="4" x2="20" y1="6" y2="6"></line>
        <line x1="4" x2="20" y1="18" y2="18"></line>
      </svg>
    );
  };
  const navItems = [
    { title: "Home", link: "/" },
    { title: "ABOUT US", link: "/about" },
    { title: "WORK", link: "/work" },
    { title: "SERVICES", link: "/services" },
    { title: "CAREER", link: "/career" },
    { title: "BLOG", link: "/blog" },
    { title: "CONTACT", link: "/contact" },
  ];

  const [show, setShow] = useState(false);
  console.log(show);
  return (
    <>
      <div className="container">
        <div className="navbar relative z-1 flex align-middle pt-[21px] pb-[22.12px] max-w-[1248px] mx-auto tablet:justify-around  desktop:justify-between phone:justify-between">
          <div className="logo m-auto">
            <div className="img-container">
              <img src={Logo} alt="" />
            </div>
          </div>
          <div
            className={`nav-items hidden justify-between items-center z-[9999] tablet:ransition-all tablet:duration-700 tablet:ease-in-out w-[737px] -translate-y-[120%] tablet:text-center tablet:absolute tablet:top-[100%] tablet:flex tablet:justify-around tablet:flex-col  tablet:w-screen tablet:bg-white tablet:overflow-hidden  ${
              show && "tablet:translate-y-0"
            } laptop:translate-y-0  laptop:flex-row laptop:relative `}
          >
            {navItems.map((items, i) => {
              return (
                <div
                  className="nav-links appearance-none transition-all duration-700 ease-in-out tablet:py-6"
                  key={i}
                >
                  <NavLink
                    to={items.link}
                    style={({ isActive }) => ({
                      color: isActive ? "black" : "gray",
                    })}
                    className="nav-text transition-all duration-700 ease-in-out "
                    onClick={() => setShow(false)}
                  >
                    {items.title}
                  </NavLink>
                </div>
              );
            })}
          </div>
          <div
            className="hamburger hidden tablet:block pt-[5px] z-[9999]  laptop:hidden phone:block mr-5 cursor-pointer"
            onClick={() => {
              setShow(!show);
            }}
          >
            {/* <img src={ham} alt="" onClick={() => setShow(!show)} /> */}
            <Menu />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
