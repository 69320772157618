import React from "react";
import img1 from "../assist/Images/fb.png";
import img2 from "../assist/Images/camera.png";
import img3 from "../assist/Images/linkn.png";
import img4 from "../assist/Images/yt.png";
import img5 from "../assist/Images/be.png";
import unitechlogoWhite from "../assist/Images/unitechlogowhite1.png";
import Group from "../assist/Images/group.png";
const Footer = () => {
  const icons = [
    {
      socials: (
        <svg
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5191 11.9597L11.103 8.11446H7.45234V5.62209C7.42698 5.33565 7.46559 5.04712 7.56532 4.77777C7.66504 4.50842 7.82334 4.26512 8.02854 4.06583C8.23373 3.86653 8.48059 3.71632 8.75091 3.62628C9.02122 3.53624 9.30809 3.50866 9.59036 3.54557H11.25V0.272822C10.2781 0.114301 9.29597 0.0276622 8.31156 0.0135942C7.63267 -0.0387354 6.95069 0.0604195 6.31395 0.304034C5.67721 0.547649 5.10128 0.929762 4.62697 1.4233C4.15265 1.91684 3.79156 2.50974 3.56927 3.15997C3.34698 3.81021 3.26893 4.50189 3.34066 5.18599V8.11446H0V11.9597H3.34066V21.25H7.45234V11.9597H10.5191Z"
            fill="#F8F8F8"
          />
        </svg>
      ),
      link: "https://www.facebook.com/unitech2012",
    },
    {
      socials: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7497 6.99584C8.95776 6.99584 7.49536 8.45824 7.49536 10.2502C7.49536 12.0422 8.95776 13.5046 10.7497 13.5046C12.5417 13.5046 14.0041 12.0422 14.0041 10.2502C14.0041 8.45824 12.5417 6.99584 10.7497 6.99584ZM20.5105 10.2502C20.5105 8.90258 20.5227 7.56713 20.447 6.22192C20.3713 4.65942 20.0149 3.2727 18.8723 2.13012C17.7273 0.985101 16.343 0.631097 14.7805 0.555414C13.4329 0.47973 12.0974 0.491937 10.7522 0.491937C9.40453 0.491937 8.06909 0.47973 6.72387 0.555414C5.16137 0.631097 3.77465 0.987542 2.63207 2.13012C1.48705 3.27514 1.13305 4.65942 1.05737 6.22192C0.981683 7.56957 0.99389 8.90502 0.99389 10.2502C0.99389 11.5955 0.981683 12.9333 1.05737 14.2786C1.13305 15.8411 1.4895 17.2278 2.63207 18.3704C3.77709 19.5154 5.16137 19.8694 6.72387 19.9451C8.07153 20.0207 9.40698 20.0085 10.7522 20.0085C12.0998 20.0085 13.4353 20.0207 14.7805 19.9451C16.343 19.8694 17.7297 19.5129 18.8723 18.3704C20.0173 17.2253 20.3713 15.8411 20.447 14.2786C20.5251 12.9333 20.5105 11.5979 20.5105 10.2502ZM10.7497 15.2576C7.97875 15.2576 5.74243 13.0212 5.74243 10.2502C5.74243 7.47924 7.97875 5.24291 10.7497 5.24291C13.5207 5.24291 15.7571 7.47924 15.7571 10.2502C15.7571 13.0212 13.5207 15.2576 10.7497 15.2576ZM15.9622 6.20727C15.3152 6.20727 14.7927 5.68481 14.7927 5.03783C14.7927 4.39086 15.3152 3.8684 15.9622 3.8684C16.6091 3.8684 17.1316 4.39086 17.1316 5.03783C17.1318 5.19146 17.1017 5.34362 17.043 5.48559C16.9843 5.62755 16.8981 5.75655 16.7895 5.86518C16.6809 5.97381 16.5519 6.05994 16.4099 6.11864C16.2679 6.17734 16.1158 6.20746 15.9622 6.20727Z"
            fill="white"
          />
        </svg>
      ),
      link: "https://instagram.com/unitech.media?igshid=OGQ2MjdiOTE=",
    },
    {
      socials: (
        <svg
          width="32"
          height="21"
          viewBox="0 0 32 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.5969 3.37948C30.4203 2.77431 30.0722 2.22199 29.5874 1.77784C29.1026 1.33368 28.4981 1.01328 27.8345 0.848714C25.3922 0.25 15.6216 0.25 15.6216 0.25C15.6216 0.25 5.85242 0.25 3.4155 0.848714C2.75189 1.01328 2.14741 1.33368 1.66261 1.77784C1.17781 2.22199 0.829686 2.77431 0.653112 3.37948C0.20294 5.65318 -0.0154112 7.9599 0.000844911 10.2702C-0.0154112 12.5805 0.20294 14.8872 0.653112 17.1609C0.830994 17.7611 1.1803 18.3077 1.66551 18.7452C2.15071 19.1826 2.75451 19.4952 3.4155 19.6513C5.85242 20.25 15.6216 20.25 15.6216 20.25C15.6216 20.25 25.3909 20.25 27.8278 19.6513C28.4902 19.4961 29.0955 19.1837 29.5819 18.746C30.0684 18.3082 30.4186 17.7609 30.5969 17.1597C31.0471 14.886 31.2654 12.5793 31.2492 10.269C31.2653 7.95909 31.047 5.65278 30.5969 3.37948ZM12.4262 14.4992V6.04002L20.5923 10.269L12.4262 14.4979V14.4992Z"
            fill="#F8F8F8"
          />
        </svg>
      ),
      link: "https://www.youtube.com/c/UnitechMedia",
    },
    {
      socials: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.59307 23.25H0.676619V8.22803H5.59307V23.25ZM3.13258 6.18543C2.56598 6.18882 2.01105 6.03271 1.53798 5.73684C1.0649 5.44097 0.694935 5.01864 0.474873 4.52327C0.254811 4.02789 0.194538 3.48173 0.301678 2.95385C0.408818 2.42597 0.678558 1.9401 1.07678 1.55768C1.475 1.17526 1.98382 0.913481 2.53887 0.805447C3.09392 0.697413 3.67028 0.74798 4.19503 0.950752C4.71979 1.15352 5.16938 1.49939 5.48693 1.94461C5.80449 2.38982 5.97574 2.91439 5.97903 3.45196C5.98344 4.1725 5.68607 4.86522 5.1523 5.37781C4.61853 5.8904 3.89205 6.18089 3.13258 6.18543ZM23.994 23.25H19.0881V15.9378C19.0881 14.1956 19.0504 11.9599 16.5251 11.9599C13.9621 11.9599 13.5776 13.8538 13.5776 15.812V23.25H8.67321V8.22803H13.3876V10.2778H13.457C13.9278 9.51081 14.6093 8.87951 15.4287 8.45125C16.2481 8.02298 17.1747 7.81384 18.1096 7.84612C23.0849 7.84612 24 10.9544 24 14.9981V23.25H23.994Z"
            fill="#F8F8F8"
          />
        </svg>
      ),
      link: "https://www.linkedin.com/company/unitechmedia",
    },
    {
      socials: (
        <svg
          width="24"
          height="16"
          viewBox="0 0 24 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8242 1.62207H21.6689V3.04004H15.8242V1.62207ZM9.96777 7.23241C11.2598 6.61425 11.9365 5.67382 11.9365 4.2207C11.9365 1.34668 9.79785 0.649414 7.32812 0.649414H0.53125V15.0752H7.51855C10.1377 15.0752 12.5957 13.8154 12.5957 10.8857C12.5957 9.07519 11.7402 7.73632 9.96777 7.23241ZM3.70117 3.11035H6.67481C7.82031 3.11035 8.84863 3.42968 8.84863 4.75976C8.84863 5.98437 8.04883 6.47656 6.91504 6.47656H3.70117V3.11035ZM7.08496 12.626H3.69824V8.65331H7.15234C8.54688 8.65331 9.42871 9.23632 9.42871 10.7129C9.42871 12.166 8.37695 12.626 7.08496 12.626ZM23.9541 10.0039C23.9541 6.91308 22.1465 4.33789 18.877 4.33789C15.6982 4.33789 13.5361 6.73144 13.5361 9.86913C13.5361 13.1211 15.584 15.3535 18.877 15.3535C21.3701 15.3535 22.9844 14.2314 23.7607 11.8379H21.2324C20.957 12.7314 19.8379 13.2002 18.9678 13.2002C17.2861 13.2002 16.4072 12.2158 16.4072 10.543H23.9336C23.9424 10.3701 23.9541 10.1885 23.9541 10.0039ZM16.4072 8.73241C16.498 7.35839 17.415 6.49999 18.7861 6.49999C20.2275 6.49999 20.9482 7.34667 21.0742 8.73241H16.4072Z"
            fill="white"
          />
        </svg>
      ),
      link: "https://www.behance.net/unitechmedia_np",
    },
  ];
  return (
    <div className=" bg-[#004A80] overflow-hidden">
      <div className="footer__container pt-[169px] max-w-[1200px] mx-auto">
        <div className="footer__contents grid place-items-center laptop:grid-cols-3 relative tablet:px-10 px-5">
          <div className="col-1 ">
            <div className="img-container mb-[22px] w-[206px]">
              <img src={unitechlogoWhite} alt="" className="object-cover" />
            </div>
            <div className="company-des mb-[70px]">
              <h3 className="font-[400] text-[16px] leading-[24px] tracking-[1.5%] text-[#ffff]">
                We believe change is possible through creativity and our mission
                is to change the Branding concept to the next level of effective
                standard; that stands simple and grand at the same time.
              </h3>
            </div>
            <div className="contact-wrapper text-center my-5">
              <div className="h2 font-[600] text-[18px] leading-[27px] text-white">
                +977 14 568111, 4593222 <br />
                hello@unitechmedia.com.np <br />
                Bhatkeko pul, Kathmandu, 44600
              </div>
            </div>
          </div>
          <div className="col-2 laptop:mt-[160px] flex flex-col desktop:items-center my-5">
            <div className="h2 font-[600] text-[18px] leading-[27px] text-white mb-[16px]">
              Quick Links
            </div>
            <div className="link-items ">
              <div className="links font-[500] text-[14px] leading-[21px] text-white">
                About us
              </div>
              <div className="links font-[500] text-[14px] leading-[21px] text-white">
                Our Works
              </div>
              <div className="links font-[500] text-[14px] leading-[21px] text-white">
                Team
              </div>
              <div className="links font-[500] text-[14px] leading-[21px] text-white">
                Contact us
              </div>
              <div className="links font-[500] text-[14px] leading-[21px] text-white">
                Brand Resources
              </div>
              <div className="links font-[500] text-[14px] leading-[21px] text-white">
                Services
              </div>
            </div>
          </div>
          <div className="col-3 laptop:mt-[200px]">
            <h2 className="links font-[600] text-[18px] leading-[27px] text-white mb-[16px]">
              Userful Links
            </h2>
            <div className="useful-desc">
              <h5 className="font-[500] text-[14px] leading-[21px] text-white">
                Engaging user content for high traffic. Design trends for 2020.
                Apps of Nepal with best UX design. Engaging user content for
                high traffic.
              </h5>
            </div>
          </div>
          <img src={Group} alt="" className="absolute top-[-100px] right-0" />
        </div>
        <div className="footer__icons flex flex-col items-center">
          <div className="icons flex w-[249px] justify-between pt-[99px] mb-[20px] items-end">
            {icons.map((el, i) => {
              console.log(el);
              return (
                <>
                  <a
                    className="cursor-pointer"
                    href={`${el.link}`}
                    target="_blank"
                  >
                    {el.socials}
                  </a>
                </>
              );
            })}
            {/* <img
              src={img1}
              alt=""
              className="w-[25px] h-[25px] object-contain"
            />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
            <img src={img5} alt="" /> */}
          </div>
          <div className="final">
            <h4 className="font-[400] text-[16px] leading-[24px] tracking-[1.5%] text-white align-middle mb-[28px]">
              © 2021 by Unitech | All rights reserved
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
